/**
 * Default Settings file for next-seo plugin
 */

export default {
  title: 'LEME – Tecnologia e serviços para recuperação de ativos',
  description:
    'A LEME Inteligência Forense oferece soluções em tecnologia e serviços para pesquisa de bens e recuperação de ativos. Conheça a Plataforma SONAR.',
  openGraph: {
    type: 'website',
    locale: 'pt_BR',
    title: 'LEME – Tecnologia e serviços para recuperação de ativos',
    description:
      'A LEME Inteligência Forense oferece soluções em tecnologia e serviços para pesquisa de bens e recuperação de ativos. Conheça a Plataforma SONAR.',
    url: 'https://lemeforense.com.br/',
    siteName: 'Leme Inteligência Forense',
    images: [
      {
        url: 'https://www.lemeforense.com.br/images/og-image.jpg',
        width: 1200,
        height: 630,
        alt: 'Leme Inteligência Forense',
        type: 'image/jpeg',
      },
    ],
  },
};
