/**
 * Default theme of the application
 * @author Lucas Santana
 * @design Leonardo Damigo
 */

// Export of type theme
export type LemeTheme = {
  theme: typeof theme;
};

export const theme = {
  colors: {
    gray025: '#FAFBFC',
    gray050: '#F5F6F7',
    gray100: '#EBEEF0',
    gray200: '#DDE2E6',
    gray300: '#D0D6DB',
    gray400: '#B1B7BE',
    gray500: '#99A1AA',
    gray700: '#56636E',
    gray800: '#414F5A',
    gray900: '#303A44',

    navalInteractive: '#0094C4',
    navalInteractive300: '#A4D7EBd',
    navalInteractive500: '#67B9D2',
    navalInteractive600: '#0083AB',
    navalInteractive700: '#006685',
    naval100: '#EAEFF1',
    naval300: '#ABCAD6',
    naval400: '#6D9AB0',
    naval600: '#2D5C75',
    naval700: '#22425C',
    naval800: '#1D3044',
    naval900: '#1E2C38',

    lemeInfoBadgeBg: '#E8F5F7',
    lemeInfoBadgeText: '#2F7480',

    coralInteractive: '#E15C3A',
    coral050: '#F7F2F2',
    coral200: '#F6DCD3',
    coral400: '#F89C8B',
    coral500: '#E5695B',
    coral600: '#C45D51',

    danger100: '#FFEAE8',
    danger600: '#BB1A27',

    success100: '#D9F1DD',
    success600: '#24744C',
  },
  fonts: {
    heading: 'Poppins, sans-serif',
    text: 'Red Hat Text, sans-serif',
    interactive: 'Red Hat Display, sans-serif',
  },
  breakpoints: {
    tablet: '860px',
    mobile: '615px',
  },
};
