/**
 * Facebook Pixel settings
 *
 * @author Lucas Santana | Leme Inteligência Forense
 */

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  try {
    window.fbq('track', 'PageView');
  } catch (e) {
    console.log('Pixel error: ' + e.message);
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  try {
    window.fbq('track', name, options);
  } catch (e) {
    console.log('Pixel error: ' + e.message);
  }
};

export const customEvent = (name, options = {}) => {
  try {
    window.fbq('trackCustom', name, options);
  } catch (e) {
    console.log('Pixel error: ' + e.message);
  }
};
